import React, {FC, useEffect, useMemo, useState} from 'react';
import {
	Avatar,
	Box,
	BoxProps,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	useMediaQuery,
} from '@chakra-ui/react';
import {
	MdAdminPanelSettings,
	MdHealthAndSafety,
	MdMedication,
	MdPerson,
	MdWork,
} from 'react-icons/md';
import useMe from '../hooks/useMe';

type UserOrbProps = BoxProps & {
	avatarMargin?: number;
};

const UserOrb: FC<UserOrbProps> = ({avatarMargin = 5, ...boxProps}) => {
	const [isMobile] = useMediaQuery(['(max-width: 30em)']);
	const [showAdmin, setShowAdmin] = useState<boolean>();
	const [showEmployee, setShowEmployee] = useState<boolean>();
	const [showEmployer, setShowEmployer] = useState<boolean>();
	const [showProvider, setShowProvider] = useState<boolean>();
	const [showPharmacy, setShowPharmacy] = useState<boolean>();
	const {me} = useMe();

	const currentPage = useMemo(() => {
		if (window.location.pathname.includes('admin')) return 'admin';
		if (window.location.pathname.includes('employer')) return 'employer';
		if (window.location.pathname.includes('patient')) return 'patient';
		if (window.location.pathname.includes('provider')) return 'provider';
		if (window.location.pathname.includes('pharmacy')) return 'pharmacy';
		return 'patient';
	}, []);

	useEffect(() => {
		if (!me || !me.roles) return;

		me.roles.forEach((role) => {
			if (role.name === 'Employer' && currentPage !== 'employer') {
				if (isMobile) {
					setShowEmployer(false);
				} else {
					setShowEmployer(true);
				}
			}
			if (role.name === 'Patient' && currentPage !== 'patient') {
				setShowEmployee(true);
			}
			if (role.name === 'Provider' && currentPage !== 'provider') {
				if (isMobile) {
					setShowProvider(false);
				} else {
					setShowProvider(true);
				}
			}
			if (role.name === 'Admin' && currentPage !== 'admin') {
				if (isMobile) {
					setShowAdmin(false);
				} else {
					setShowAdmin(true);
				}
			}
			if (role.name === 'Pharmacy' && currentPage !== 'pharmacy') {
				if (isMobile) {
					setShowPharmacy(false);
				} else {
					setShowPharmacy(true);
				}
			}
		});
	}, [currentPage, me, isMobile]);

	if (!me) return null;

	return (
		<Box
			pos='fixed'
			left={0}
			bottom={{base: 'unset', sm: 0}}
			top={{base: 0, sm: 'unset'}}
			zIndex='sticky'
			{...boxProps}
		>
			<Menu closeOnBlur>
				<MenuButton data-e2e-id='profile-avatar'>
					<Avatar
						size={{base: 'md', sm: 'sm'}}
						m={avatarMargin}
						name={`${me.first_name} ${me.last_name}` ?? ''}
					/>
				</MenuButton>
				<MenuList>
					{showAdmin && (
						<MenuItem
							as={Link}
							href='/admin'
							data-e2e-id='admin'
							icon={<MdAdminPanelSettings />}
						>
							Switch to Admin Portal
						</MenuItem>
					)}
					{showProvider && (
						<MenuItem
							as={Link}
							href='/provider'
							icon={<MdHealthAndSafety />}
							data-e2e-id='provider'
						>
							Switch to Provider Portal
						</MenuItem>
					)}
					{showPharmacy && (
						<MenuItem
							as={Link}
							href='/pharmacy'
							icon={<MdMedication />}
							data-e2e-id='pharmacy'
						>
							Switch to Pharmacy Portal
						</MenuItem>
					)}
					{showEmployer && (
						<MenuItem
							as={Link}
							href='/employer'
							icon={<MdWork />}
							data-e2e-id='employer'
						>
							Switch to Employer Portal
						</MenuItem>
					)}
					{showEmployee && (
						<MenuItem
							as={Link}
							href='/patient'
							icon={<MdPerson />}
							data-e2e-id='patient'
						>
							Switch to Patient Site
						</MenuItem>
					)}
					{showEmployee || showEmployer || showProvider || showAdmin ? (
						<MenuDivider />
					) : null}
					{currentPage === 'patient' && (
						<MenuItem>
							<Link href='/patient/personal-information' data-e2e-id='profile'>
								Edit Profile
							</Link>
						</MenuItem>
					)}
					<MenuItem as={Link} href='/logout' data-e2e-id='logout-button'>
						Logout
					</MenuItem>
				</MenuList>
			</Menu>
		</Box>
	);
};

export default UserOrb;
