/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class UserController {
	static async me(
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<
			Glum.Models.User & {is_impersonating: boolean; latitude: number; longitude: number}
		>(`/api/user/me`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async updateMe(
		request: Glum.Requests.Employee.EditMe = {} as Glum.Requests.Employee.EditMe,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.put(`/api/user/me`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async changeMyPassword(
		request: Glum.Requests.Auth.ChangePassword = {} as Glum.Requests.Auth.ChangePassword,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<{message: string}>(`/api/user/me/change-password`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async changeTemporaryPassword(
		request: Glum.Requests.Auth.UserPassword = {} as Glum.Requests.Auth.UserPassword,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post(`/api/user/me/change-temp-password`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async listAdmins(
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.User[]>(`/api/admin/users/admins`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async index(
		request: {page: number; per_page: number; search: string},
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<PaginatedResponse<Glum.Models.User>>(
			`/api/admin/users?${transformToQueryString(request)}`,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async store(
		request: Glum.Requests.Admin.AddUser = {} as Glum.Requests.Admin.AddUser,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post(`/api/admin/users`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async show(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get(`/api/admin/users/${user}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async update(
		user: string | number,
		request: Glum.Requests.Admin.EditUser = {} as Glum.Requests.Admin.EditUser,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.put<{message: string; user: Glum.Models.User}>(
			`/api/admin/users/${user}`,
			request,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async destroy(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.delete<{success: boolean}>(`/api/admin/users/${user}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async activate(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<{success: boolean}>(`/api/admin/users/${user}/activate`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async unlockUser(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post(`/api/admin/users/${user}/unlock-user`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async deleteTokens(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.delete<{success: boolean}>(`/api/admin/users/${user}/tokens`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async resetUserPassword(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post(`/api/admin/users/${user}/reset-password`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}
}
